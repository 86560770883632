import React from 'react'
import { graphql } from 'gatsby';
import ArticlePage from '../components/ArticlePage';

const Loader = props => <ArticlePage {...props} />

export default Loader

export const query = graphql`
    query {
        mdx(slug: {eq: "how-to-start-a-blog/"}) {
          slug
          body
          excerpt
          timeToRead
          id
          frontmatter {
            title
            cover_image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      
  `